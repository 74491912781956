<template>
  <div>
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <div
          class="d-flex align-self-center justify-center align-center mt-md-5"
        >
          <img
            width="40%"
            src="../../assets/conteudos_casas_title_03.png"
            alt=""
          />
        </div>
      </v-row>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title="A vedação ou parede de pau a pique"
            >
              <template v-slot:conteudo>
                <p>
                  A técnica tradicional de pau a pique é um tipo de vedação onde
                  se executa uma trama formada por varas posicionadas
                  verticalmente (que são os paus a pique, ou madeiras aprumadas
                  “em pé”) fixadas no respaldo da fundação de pedras ou sobre
                  uma peça de madeira, no baldrame. Amarradas com fibra de cipó
                  ou taquara a essas varas, são fixadas outras horizontalmente
                  (varas de madeira, taquara ou bambu), formando assim uma
                  trama. O espaço do painel entre as varas é preenchido com uma
                  massa de barro e depois rebocado com argamassa de cal, areia e
                  barro, e em seguida recebe a pintura, geralmente à base de
                  cal. As varas horizontais são instaladas de maneira
                  intercalada de cada face do caibro vertical; assim, ao se
                  lançar o barro na trama (gradeado), a massa se fixa a ela e
                  não atravessa para o outro lado.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_19.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Casa arruinada em um terreno da comunidade de Ponte do Gama
            construída com paredes de pau a pique. Foto: Vistoria técnica da
            Cáritas, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_20.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Foto: Larissa Pinto, Jornal A Sirene, 2018.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_21.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Paiol construído com paredes de pau a pique em um terreno da
            comunidade de Pedras. Foto: Vistoria técnica da Cáritas, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title="Alvenarias"
              title_alignment="center"
            >
              <template v-slot:conteudo>
                <p>
                  A alvenaria é uma técnica de execução de paredes e muros
                  empilhando-se blocos. Para isso, são utilizados pedras,
                  lajotas, tijolos, aglutinados entre si por meio de uma
                  argamassa. No período colonial, as argamassas mais utilizadas
                  eram de cal e areia ou de barro.
                </p>
              </template>
            </Card>
          </div>
        </v-col>

        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title="Alvenaria de pedra"
              title_alignment="center"
            >
              <template v-slot:conteudo>
                <p>
                  Geralmente utilizada nos alicerces, a alvenaria de pedras
                  também foi usada na construção de paredes, sendo mais comum em
                  igrejas, edificações de uso militar e público, caracterizada
                  por possuir grande resistência e durabilidade. As pedras
                  utilizadas eram calcários, arenitos ou pedra de rio e
                  granitos, destacando-se a pedra-sabão e a canga, em Minas. As
                  argamassas usadas para unir os blocos eram de cal e areia,
                  mais resistente, ou o barro, onde não existia a
                  disponibilidade de cal. As pedras eram de tamanho variável e
                  acabamento irregular (sem aparelhagem). As pedras menores eram
                  assentadas para calçar as maiores e desta forma ia-se obtendo
                  o nivelamento. No caso da alvenaria de pedra seca não é
                  utilizada argamassa, sendo as pedras encaixadas de modo que
                  fiquem travadas, e as paredes ficam com grande espessura (de
                  0,60 a 1,00m). Esta técnica é mais comum em muros externos. Em
                  torno dos blocos maiores, são assentadas pedras menores,
                  recebendo o nome de “cangicado”.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_22.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista de muros executados com alvenaria de pedras em um terreno de
            Santa Rita Durão. Foto: ETII/IPHAN/Mariana, 2021.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_23.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Casa com alvenaria de pedras no nível do porão, construída em um
            terreno da comunidade de Pedras. Foto: Vistoria técnica da Cáritas,
            2019.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_24.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Muro de alvenaria de pedras executado ao lado de um casarão em
            terreno rural de Paracatu de Baixo. Foto: Vistoria técnica da
            Cáritas, 2018.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_25.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Muro de alvenaria de pedra seca construído em um terreno da
            comunidade de Pedras. Foto: Vistoria técnica da Cáritas, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_26.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Fôrma de madeira utilizada para a execução dos tijolos de adobe,
            encontrada em uma casa de Paracatu de Baixo. Foto: Vistoria da
            Cáritas, 2018.
          </p>
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title="A alvenaria de adobe"
            >
              <template v-slot:conteudo>
                <p>
                  Além do pau a pique, na região de Mariana eram utilizados
                  tijolos de adobe para erguer as alvenarias. A técnica de
                  fabricação de adobe consiste inicialmente em uma massa de
                  barro, areia, água e fibras vegetais misturada com os pés para
                  se ter homogeneidade e boa adesão. São produzidos tijolos ou
                  lajotas de aproximadamente 20x20x40cm, com uma massa
                  compactada manualmente, colocada em fôrmas de madeira e secada
                  à sombra durante vários dias e depois ao sol. O barro deve ter
                  quantias corretas de argila e areia, para não ficar nem muito
                  quebradiça, nem demasiadamente plástica. Para melhorar sua
                  resistência, pode-se acrescentar fibras vegetais ou estrume de
                  boi. O assentamento entre os tijolos é feito com a própria
                  massa de barro e podem receber um reboco feito de areia e cal
                  para aumentar sua durabilidade e resistência. As alvenarias de
                  adobe podem ser autoportantes, ou seja, com amarração nos
                  cantos, e receber a carga do telhado. Também podem conformar
                  os fechamentos em estruturas compostas por peças de madeira
                  (pilares e vigas), em edificações, por exemplo, onde o pé
                  direito é mais alto ou as paredes possuem grandes dimensões. O
                  adobe era utilizado nas construções tanto de residências
                  quanto de templos religiosos, de forma mista com outras
                  técnicas construtivas como pau a pique e alvenarias de pedra.
                  Existem importantes construções feitas inteiramente de adobe,
                  como a matriz de Santa Rita Durão, mas o material era bastante
                  usado em divisórias internas.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_27.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Parede de uma casa em Paracatu de Baixo construída com tijolos de
            adobe. Foto: Vistoria da Cáritas, 2018.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_28.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Alvenarias de adobe em uma casa da comunidade de Paracatu de Baixo.
            Foto: Vistoria técnica da Cáritas, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title="Tijolos cerâmicos"
            >
              <template v-slot:conteudo>
                <p>
                  Conhecido como tijolinho ou tijolo maciço, o tijolo cerâmico,
                  assim como o adobe, é feito com a mesma matéria prima, o barro
                  argiloso. No entanto, possui dimensões menores e é cozido em
                  fornos com temperaturas elevadas, possuindo alta durabilidade.
                  O acesso a este material de grande durabilidade era favorável
                  à permanência dos assentamentos junto aos aluviões dos rios.
                  Desde o século XVII, o tijolo maciço era usado na Bahia, e há
                  registro de que em 1711 já havia uma olaria em Ouro Preto. No
                  entanto, a maior parte da produção das olarias da região de
                  Mariana era voltada para telhas. As alvenarias de tijolos
                  somente se tornarão comuns no século XIX. Nos séculos
                  anteriores, predominava o uso da taipa de pilão, de pedra e
                  cal, e do adobe. A partir do advento do tijolo maciço foi
                  comum a adoção de fiadas desse material associadas à pedra em
                  paredes de pedra e cal, assim como trechos nas paredes de pau
                  a pique e adobe.
                </p>
              </template>
            </Card>
          </div>
        </v-col>

        <v-col
          cols="12"
          md="8"
          class="d-flex justify-center align-center flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_29.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Casa com fachada de alvenaria de tijolos maciços construída em um
            terreno de Paracatu de Baixo. Foto: Vistoria técnica da Cáritas,
            2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title="A Taipa de pilão"
            >
              <template v-slot:conteudo>
                <p>
                  Outras técnicas construtivas com barro eram comuns no Brasil
                  colônia, a exemplo da taipa de pilão; entretanto, essa técnica
                  foi mais utilizada nas regiões de São Paulo e Goiás. Em Minas,
                  é encontrada em igrejas e casas mais antigas. Na região de
                  Mariana, especificamente nas comunidades atingidas, não foram
                  identificadas casas com paredes de taipa de pilão. Cabe
                  observar que essa técnica, por ser menos resistente à erosão
                  provocada por enxurradas, foi menos utilizada em construções
                  rurais, principalmente em regiões de terreno mais declivoso,
                  como é o caso de Mariana.
                </p>
                <p>
                  Nessa técnica, o barro (contendo areia na composição) é
                  colocado em fôrmas de tábuas de madeira travadas e compactado
                  (socado) com um pilão. As tábuas da fôrma são colocadas nas
                  laterais e estruturadas por montantes de madeira. A espessura
                  final da parede é bem grossa, entre 0,6 m e 1 m. Depois do
                  trecho de barro estar seco, a fôrma é desmontada e deslocada
                  para a posição acima, e assim sucessivamente.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_30.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Parede à esquerda da imagem construída com a técnica da taipa de
            pilão em uma casa de Paracatu de Baixo. Foto: Vistoria da Cáritas,
            2018.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_31.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Registro fotográfico da execução da taipa de pilão. Foto: Autor e
            data não identificados.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center pa-4">
        <v-col class="d-flex justify-center align-center">
          <v-btn
            :to="{ name: 'conteudos_tematicos_casas_portas_janelas' }"
            color="#EDBD43"
          >
            <strong>Conhecer "Portas e Janelas"</strong></v-btn
          >
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Casa /",
          href: "conteudos_tematicos_casas",
        },

        {
          text: "Técnicas Construtivas Tradicionais /",
          href: "conteudos_tematicos_casas_tecnicas_construtivas",
        },

        {
          text: "As Paredes",
          href: "conteudos_tematicos_casas_paredes",
        },
      ],
    };
  },
};
</script>

<style scoped>
.section__margin-bottom {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

p {
  font-size: 0.6em;
}
@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }
  .border__image {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }

  .border__image2 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image2::before {
    display: none;
  }

  .border__image3 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image3::before {
    display: none;
  }
  .border__image4 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image4::before {
    display: none;
  }

  .border__image5 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image5::before {
    display: none;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.arvores_bg {
  background-image: url("../../assets/conteudo_genero_arvores.png");
  background-size: cover;
  width: 100%;
  height: 650px;
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_02.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
.border__image h3 {
  color: #e06919;
  font-size: 0.5em;
}

.border__image2 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_03.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image3 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image3::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg2.png");
  background-size: 100% 100%;
}

.border__image3 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image4 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image4::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg3.png");
  background-size: 100% 100%;
}

.border__image4 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image5 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image5::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg3.png");
  background-size: 100% 100%;
}

.border__image5 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
</style>
